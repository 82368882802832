import React from 'react'

const InstagramIcon = () => (
    <svg 
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        x="0px" y="0px"
        viewBox="0 0 169.1 169.1" 
        style={{
            enableBackground: `new 0 0 169.1 169.1`,
            maxWidth: `1.25rem`,
            marginRight: `.5rem`
            }} >
        <g>
            <path style={{fill: `#FFFFFF`}} d="M122.4,0H46.7C20.9,0,0,20.9,0,46.7v75.8c0,25.7,20.9,46.7,46.7,46.7h75.8c25.7,0,46.7-20.9,46.7-46.7V46.7
                C169.1,20.9,148.1,0,122.4,0z M154.1,122.4c0,17.5-14.2,31.7-31.7,31.7H46.7c-17.5,0-31.7-14.2-31.7-31.7V46.7
                C15,29.2,29.2,15,46.7,15h75.8c17.5,0,31.7,14.2,31.7,31.7L154.1,122.4L154.1,122.4z"/>
            <path style={{fill: `#FFFFFF`}} d="M84.5,41C60.5,41,41,60.5,41,84.5c0,24,19.5,43.6,43.6,43.6s43.6-19.5,43.6-43.6C128.1,60.5,108.6,41,84.5,41z
                M84.5,113.1c-15.7,0-28.6-12.8-28.6-28.6C56,68.8,68.8,56,84.5,56s28.6,12.8,28.6,28.6C113.1,100.3,100.3,113.1,84.5,113.1z"/>
            <path style={{fill: `#FFFFFF`}} d="M129.9,28.3c-2.9,0-5.7,1.2-7.8,3.2c-2.1,2-3.2,4.9-3.2,7.8c0,2.9,1.2,5.7,3.2,7.8c2,2,4.9,3.2,7.8,3.2
                c2.9,0,5.7-1.2,7.8-3.2c2.1-2,3.2-4.9,3.2-7.8c0-2.9-1.2-5.7-3.2-7.8C135.7,29.4,132.8,28.3,129.9,28.3z"/>
        </g>
    </svg>
)

export default InstagramIcon;